<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="purple">
            <v-toolbar-title>{{ $t('Login') }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field v-model="form.email" prepend-icon="mdi-account" :label="$t('Email')" type="text"></v-text-field>
              <v-text-field v-model="form.password" prepend-icon="mdi-lock" :label="$t('Password')" type="password"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="purple" @click="onSubmit" :loading="loading" :disabled="loading">{{ $t('Login') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import localStorageService from '@/services/localStorageService'
import authService from '@/services/authService'

export default {
  data() {
    return {
      loading: false,
      form: {
        email: null,
        password: null,
      }
    }
  },
  created() {
    if (localStorageService.getAuthToken()) {
      this.$router.push('/')
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true
      try {
        const result = await authService.login(this.form)
        localStorageService.setAuthToken(result.data.user.token.token)
        // localStorage.setItem('user', JSON.stringify(result.data.user))
        this.$router.push(this.$route.query.redirect || '/')
      } catch (error) {
        this.$dialog.message.error(error.message)
      }
      this.loading = false
    }
  }
}
</script>
